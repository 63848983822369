import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import ClipboardJS from "clipboard";
import "react-datepicker/dist/react-datepicker.css";
import { useAtom } from "jotai";
import {
  customerIdAtom,
  tokenAtom,
  selectedPortfolioIdAtom,
  selectedFromDateAtom,
  selectedToDateAtom,
  selectedCurrencyAtom,
  useCanadianCostBasisAtom,
  useSbiTtRatesAtom,
} from "../state/atoms";
import { Form } from "react-bootstrap";
import BarChart, { BarChartPnlElement } from "./BarChart";

interface PortfolioOption {
  portfolio_id: number;
  portfolio_name: string;
  is_default?: boolean;
}

interface RealizedPnLRow {
  portfolio_name: string;
  currency: string;
  symbol: string;
  buy_dt: string;
  sell_dt: string;
  entry_price?: number;
  entry_price_actual: number;
  exit_qty: number;
  exit_price: number;
  commissions_and_fees: number;
  pnl?: number;
  pnl_pct?: number;
  num_holding_days: number;
  gain_type: string;
  exch_pair: string;
  exch_rate: number;
  reporting_currency: string;
  pnl_rc?: number;
  tds_paid?: number;
  tax_paid?: number;
}

const RealizedPnL: React.FC = () => {
  const [customerId] = useAtom(customerIdAtom);
  const [token] = useAtom(tokenAtom);
  const [selectedPortfolioId, setSelectedPortfolioId] = useAtom(selectedPortfolioIdAtom);
  const [fromDate, setFromDate] = useAtom(selectedFromDateAtom);
  const [toDate, setToDate] = useAtom(selectedToDateAtom);
  const [currency, setCurrency] = useAtom(selectedCurrencyAtom);
  const [portfolios, setPortfolios] = useState<PortfolioOption[]>([]);
  const [positions, setPositions] = useState<RealizedPnLRow[]>([]);
  const [filterText, setFilterText] = useState<string>("");
  const [useCanadianCostBasis, setUseCanadianCostBasis] = useAtom(useCanadianCostBasisAtom);
  const [useSbiTtRates, setUseSbiTtRates] = useAtom(useSbiTtRatesAtom);
  const tableRef = useRef<HTMLTableElement>(null);

  const handleToggle = () => {
    setUseCanadianCostBasis(!useCanadianCostBasis);
  };

  const handleSbiTtToggle = () => {
    setUseSbiTtRates(!useSbiTtRates);
  };

  const handleCopyClick = () => {
    if (tableRef.current) {
      const clipboard = new ClipboardJS("#copyButton", {
        target: () => tableRef.current as HTMLElement,
      });

      clipboard.on("success", (e) => {
        console.log("Table copied successfully!");
        e.clearSelection();
      });

      clipboard.on("error", (e) => {
        console.error("Failed to copy table:", e);
      });

      // clipboard.onClick({ currentTarget: document.getElementById("copyButton") });
    }
  };

  useEffect(() => {
    const fetchPortfolios = async () => {
      const config = { headers: { Authorization: `Bearer ${token.accessToken}` } };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolios`, config);
      setPortfolios([{ portfolio_id: 0, portfolio_name: "All" }, ...response.data]);
      if (selectedPortfolioId < 0) {
        var defaultPortfolio = response.data.find((portfolio: PortfolioOption) => portfolio.is_default || false);
        if (defaultPortfolio) {
          setSelectedPortfolioId(defaultPortfolio.portfolio_id);
        } else {
          setSelectedPortfolioId(0);
        }
      }
    };

    if (token) fetchPortfolios();
  }, [token, customerId, setSelectedPortfolioId, selectedPortfolioId]);

  useEffect(() => {
    if (selectedPortfolioId < 0) return;
    const fetchPositions = async () => {
      const config = {
        headers: { Authorization: `Bearer ${token.accessToken}` },
        params: {
          reporting_currency: currency,
          from_dt: fromDate.toISOString().split("T")[0],
          to_dt: toDate.toISOString().split("T")[0],
          use_canadian_cost_basis: useCanadianCostBasis,
          use_sbi_tt_rates: useSbiTtRates,
        },
      };
      const url = `${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolio/${selectedPortfolioId}/realized_pnls_by_date`;
      const response = await axios.get(url, config);
      setPositions(response.data);
    };

    if (token && selectedPortfolioId !== undefined) fetchPositions();
  }, [selectedPortfolioId, fromDate, toDate, currency, useCanadianCostBasis, useSbiTtRates, token, customerId]);

  const containesAnyWord = (text: string, phrase: string) => {
    if (phrase.trim() === "") return true;
    const negate = phrase.includes("!");
    if (negate) {
      phrase = phrase.replaceAll("!", "");
    }
    if (phrase.trim() === "") return true;
    const words: string[] = phrase.toLowerCase().replaceAll(",", " ").trim().split(" ");
    return negate
      ? words.every((word) => word.trim() !== "" && text.toLowerCase().startsWith(word) === false)
      : words.some((word) => word.trim() !== "" && text.toLowerCase().startsWith(word));
  };

  //. const barChartPnlElements = positions.map((position) => ({ symbol: position.symbol, pnl: position.pnl_rc || 0 }));
  // Aggregate sums by symbol
  const barChartPnlElements = positions.reduce((acc: BarChartPnlElement[], position) => {
    const index = acc.findIndex((item) => item.symbol === position.symbol);
    if (index === -1) {
      acc.push({ symbol: position.symbol, pnl: position.pnl_rc || 0 });
    } else {
      acc[index].pnl += position.pnl_rc || 0;
    }
    return acc;
  }, [] as BarChartPnlElement[]);
  barChartPnlElements.sort((a, b) => b.pnl - a.pnl);
  const filteredPositions = positions.filter((position) => containesAnyWord(position.symbol, filterText));
  const pnl_short_term = filteredPositions.reduce((sum, position) => sum + (position.gain_type === "Short-Term" ? position.pnl_rc || 0 : 0), 0);
  const pnl_long_term = filteredPositions.reduce((sum, position) => sum + (position.gain_type === "Long-Term" ? position.pnl_rc || 0 : 0), 0);
  const pnl_total = filteredPositions.reduce((sum, position) => sum + (position.pnl_rc || 0), 0);
  const taxes_paid = -filteredPositions.reduce((sum, position) => sum + (position.tax_paid || position.tds_paid || 0) * position.exch_rate, 0);

  return (
    <div className="container">
      <div className="title-bar">
        <h3>Realized P&amp;Ls</h3>
      </div>
      <div className="filter-bar bg-secondary p-2">
        <div className="filter-item align-items-center col-lg-4">
          <label className="font-bold">Portfolio:</label>
          <select className="form-control" value={selectedPortfolioId} onChange={(e) => setSelectedPortfolioId(Number(e.target.value))}>
            {portfolios.map((portfolio) => (
              <option key={portfolio.portfolio_id} value={portfolio.portfolio_id}>
                {portfolio.portfolio_name}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-item align-items-center col-lg-4">
          <label>Date Range: </label>
          <DatePicker className="form-control" selected={fromDate} onChange={(date: Date) => setFromDate(date)} /> to{" "}
          <DatePicker className="form-control" selected={toDate} onChange={(date: Date) => setToDate(date)} />
        </div>
        <div className="filter-item align-items-center col-lg-4">
          <label>Currency: </label>
          <select className="form-control" value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="USD">USD</option>
            <option value="CAD">CAD</option>
            <option value="INR">INR</option>
          </select>
        </div>
      </div>
      <div className="filter-bar bg-secondary p-2">
        <Form.Check type="switch" id="canadian-cost-basis-switch" label="Canadian Cost Basis" checked={useCanadianCostBasis} onChange={handleToggle} />
        <Form.Check type="switch" id="sbi-tt-switch" label="SBI TT Rates" checked={useSbiTtRates} onChange={handleSbiTtToggle} />
      </div>

      <div className="quick-filter-box bg-secondary p-2">
        <input type="text" className="form-control" value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Filter by symbol..." />
      </div>

      {barChartPnlElements.length > 0 && (
        <div className="summary-container">
          <BarChart data={barChartPnlElements} />
        </div>
      )}

      {/* Summary Section */}
      <div className="summary-container">
        <div className="summary-box">
          <div className="summary-title">Short-Term P&amp;L</div>
          <div className={(pnl_short_term || 0) > 0 ? "summary-number green-cell" : "summary-number red-cell"}>
            {pnl_short_term.toLocaleString(undefined, { style: "currency", currency: currency })}
          </div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Long-Term P&amp;L</div>
          <div className={(pnl_long_term || 0) > 0 ? "summary-number green-cell" : "summary-number red-cell"}>
            {pnl_long_term.toLocaleString(undefined, { style: "currency", currency: currency })}
          </div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Total P&L</div>
          <div className={(pnl_total || 0) > 0 ? "summary-number green-cell" : "summary-number red-cell"}>
            {pnl_total.toLocaleString(undefined, { style: "currency", currency: currency })}
          </div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Tax Paid/Credit</div>
          <div className={(taxes_paid || 0) > 0 ? "summary-number green-cell" : "summary-number red-cell"}>
            {taxes_paid.toLocaleString(undefined, { style: "currency", currency: currency })}
          </div>
        </div>
      </div>

      <div className="right-al">
        <button className="btn" id="copyButton" onClick={handleCopyClick}>
          📋 Copy
        </button>
      </div>
      <table className="table table-striped" ref={tableRef}>
        <thead>
          <tr>
            <th className="numeric-cell">#</th>
            {selectedPortfolioId === 0 ? <th>Portfolio</th> : null}
            <th>Symbol</th>
            <th>Currency</th>
            <th className="numeric-cell">Buy Date</th>
            <th className="numeric-cell">Sell Date</th>
            <th className="numeric-cell">Quantity</th>
            <th className="numeric-cell">Avg Price</th>
            <th className="numeric-cell">Sell Price</th>
            <th className="numeric-cell">Commissions</th>
            <th className="numeric-cell">Holding Days</th>
            <th>ST/LT</th>
            <th className="numeric-cell">P&L</th>
            <th className="numeric-cell">P&L%</th>
            <th className="numeric-cell">Exch Rate</th>
            <th className="numeric-cell">P&L (in {currency})</th>
          </tr>
        </thead>
        <tbody>
          {filteredPositions.map((position, index) => (
            <tr key={index}>
              <td className="numeric-cell">{index + 1}</td>
              {selectedPortfolioId === 0 ? <td>{position.portfolio_name}</td> : null}
              <td>{position.symbol}</td>
              <td>{position.currency}</td>
              <td className="numeric-cell">{position.buy_dt}</td>
              <td className="numeric-cell">{position.sell_dt}</td>
              <td className="numeric-cell">{position.exit_qty.toLocaleString()}</td>
              <td className={(Math.abs((position.entry_price || 0) - position.entry_price_actual) > 1e-2 ? "adj-price-cell " : "") + "numeric-cell"}>
                {position.entry_price?.toLocaleString(undefined, { style: "currency", currency: position.currency })}
              </td>
              <td className="numeric-cell">{position.exit_price.toLocaleString(undefined, { style: "currency", currency: position.currency })}</td>
              <td className="numeric-cell">{position.commissions_and_fees.toLocaleString(undefined, { style: "currency", currency: position.currency })}</td>
              <td className="numeric-cell">{position.num_holding_days.toLocaleString()}</td>
              <td>{position.gain_type.toLocaleString()}</td>
              <td className="numeric-cell">{position.pnl?.toLocaleString(undefined, { style: "currency", currency: position.currency })}</td>
              <td className="numeric-cell">{position.pnl_pct?.toLocaleString()}%</td>
              <td className="numeric-cell">{position.exch_rate?.toFixed(6)}</td>
              <td className="numeric-cell">{position.pnl_rc?.toLocaleString(undefined, { style: "currency", currency: currency })}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RealizedPnL;
