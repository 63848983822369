// src/components/Login.tsx
import React, { useState } from "react";
import { useSetAtom } from "jotai";
import { tokenAtom, customerIdAtom } from "../state/atoms";
import { login } from "../api/services";

export const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const setToken = useSetAtom(tokenAtom);
  const setCustomerId = useSetAtom(customerIdAtom);

  const handleLogin = async () => {
    try {
      const data = await login(username, password);
      setToken({ accessToken: data.access_token, refreshToken: data.refresh_token });
      readCustomerId(data.access_token);
    } catch (error) {
      alert("Login failed. Please try again.");
    }
  };

  const readCustomerId = (token: string) => {
    // Read cid from access token print
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    const payload = JSON.parse(jsonPayload);

    if (payload?.app_metadata?.cid) {
      setCustomerId(payload.app_metadata.cid);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card shadow-sm p-4">
            <h3 className="card-title text-center mb-3">Login</h3>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username
              </label>
              <input
                type="text"
                id="username"
                className="form-control"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <button className="btn btn-primary w-100" onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
